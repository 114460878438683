export default [
  {
    key: 'regionCode',
    label: 'field.regionCode',
    rules: 'required|max:100',
    type: 'text',
    disabled: true,
  },
  {
    key: 'telegramId',
    label: 'field.telegram',
    rules: 'max:50',
    type: 'text',
  },
]
